@import '../../styles/partials/variables';

.footer {
	box-sizing: border-box;
    width: 100%;
	height: 14rem;
    background-color: $white;
	padding-left: calc(100% * 0.382 / 2);
	padding-right: calc(100% * 0.382 / 2);
	padding-top: 7.2rem;
    position: relative; 
    
	>a, >span {
		text-decoration: none;
		display: inline-block;
			
		&:not(:last-child) { /* normal links */
			color: $black;
			font-size: 1.5rem;
			min-width: 16rem;
			float: left;
			text-align: left;

			&:nth-child(3) { /* Copyright Link */
				text-align: right;
				float: right;
				font-size: 1.2rem;
				padding-top:0.3rem;
			}
		}
		
		&:last-child { /* logo link */
			background-image: url('/assets/images/logo.svg');
			width: 18rem;
			height: 4rem;
			overflow: hidden;
			color: transparent;
			background-repeat: no-repeat;
			background-size: 10rem;
			background-position: center;
		    padding-left: 3.6rem;
		    padding-right: 3.6rem;
		    position: absolute;
		    left: 50%;
		    top: 5.8rem;
		    transform: translate(-50%, 0);
   		}
		
	}
}
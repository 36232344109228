@font-face {
	font-family: 'Roboto Light';
	src: url('/assets/fonts/roboto-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: url('/assets/fonts/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto-medium-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Bold';
    src: url('/assets/fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

html {
	font-size: 0.625em; // based on 10px and bias for design document. 1rem equals 10px in design document.
}

body{
	font-family: Roboto Light, sans-serif;
	font-size: 2rem;
	margin: 0;
}
@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.services {
	background-color: $white;
	width: 100%;
	height: 67.3rem;
	position: relative;
	
	>h1 {
		margin: 0;
		font-size: 8rem;
		font-family: 'Roboto';
		font-weight: bold;
		position: absolute;
		awidth: calc(50% - 16rem);
		display: inline-block;
		left: 16rem;
		top: 50%;
		transform: translate(0, -61%);
		word-break: break-word;
				
		>span {
			@include whirly-span;
		}
	}
	
	>p {
		margin: 0;
		font-size: 2rem;
		line-height: 2;
		position: absolute;
		width: calc(50% - 16rem);
		right: 16rem;
		top: 50%;
		transform: translate(0, -50%);
		word-break: break-word;
	}
}
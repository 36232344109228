@mixin whirly-span {
	display: inline-block;
	width: 2.5rem;
	height: 7rem;
	margin-left: 1rem;
	font-size: 0;
	
	&.visible {
		@keyframes show-dot {
		 0% { 
			background: none;
			opacity: 1;
		 }
		 100% {
			background: radial-gradient(circle 1.3rem at calc(1.3rem / 2) calc(7rem - 1.3rem / 2), $black 0, $black 50%, transparent 50%);
			opacity: 1;
		 }
		}
		
		@keyframes hide-dot {
		 100% {
			opacity: 0;
		 }
		}
		
		animation: show-dot, hide-dot;
		animation-duration: 0s, 0.5s;
		animation-delay: 1s, 2s;
		animation-fill-mode: forwards, forwards;
		
		&:after {
			@keyframes rotate {
			 0% { 
				background: radial-gradient(circle 1.3rem at calc(1.3rem / 2) calc(7rem - 1.3rem / 2), $black 0, $black 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(2.5rem - 1.3rem / 2) calc(3.9rem + 1.3rem / 2), $green 0, $green 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(2.5rem - 1.3rem / 2) calc(1.85rem + 1.3rem / 2), $green-light 0, $green-light 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(1.3rem / 2) calc(1.3rem / 2), $green-lighter 0, $green-lighter 50%, transparent 50%);
				transform: rotate(-180deg);
				transform-origin: -2rem center;
				animation-timing-function: ease-out;
			 }
			 100% {
				background: radial-gradient(circle 1.3rem at calc(1.3rem / 2) calc(7rem - 1.3rem / 2), $black 0, $black 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(2.5rem - 1.3rem / 2) calc(3.9rem + 1.3rem / 2), $green 0, $green 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(2.5rem - 1.3rem / 2) calc(1.85rem + 1.3rem / 2), $green-light 0, $green-light 50%, transparent 50%),
					radial-gradient(circle 1.3rem at calc(1.3rem / 2) calc(1.3rem / 2), $green-lighter 0, $green-lighter 50%, transparent 50%);
				transform: rotate(0);
				transform-origin: -2rem center;
			 }
			}
					
			@keyframes fade-in {
			 0% { 
			 	opacity: 0;
				animation-timing-function: ease-in;
			 }
			 100% {
			 	opacity: 1;
			 }
			}
					
			@keyframes fade-out {
			 0% { 
			 	opacity: 1;
				animation-timing-function: ease-in-out;
			 }
			 100% {
			 	opacity: 0;
			 }
			}
		
			content: "";
			display: inline-block;
			width: 2.5rem;
			height: 7rem;
			opacity: 1;
			animation: rotate, fade-in, fade-out;
			animation-duration: 1s, 0.5s, 0.5s;
			animation-delay: 0s, 0s, 1.5s;
			animation-fill-mode: forwards, forwards, forwards;
		}
	}			
}
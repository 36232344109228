@import '../../styles/partials/variables';

.slides {
	width: 100%;
	height: calc(6.5 * 67.3rem - 7rem);
	margin-top: 7rem;

	/* variables */	
	--slide-peek-offset: -100rem;
	--slide-speed: 0.5s;
		
	>div {
		width: 100%;
		height: calc(100vh - 7rem);
		position: sticky;
		overflow: hidden;
		top: 7rem;
			
		>div {
			width: 100%;
			height: 100%;
			position: absolute;
			transition: transform var(--slide-speed);
			
			/* animation is faded out already at 50+%, so we will only reach a bit more then 10vh zoom */
			@keyframes zoom {
				0% { 
					background-size: calc(var(--background-size) + 0vh); 
					background-position:  calc(var(--background-position-x) + 0vw) center; 
				}
				100% {
					background-size: calc(var(--background-size) + 20vh); 
					background-position:  calc(var(--background-position-x) + -2vw) center; 
				}
			}
			
			@keyframes blend-out-in {
				0% { 
					opacity: 0.27;
				}
				15% {
					opacity: 0;
				}
				50% {
					opacity: 0;
				}
				65% {
					opacity: 0.27;
				}
				100% {
					opacity: 0.27;
				}
			}

			@keyframes blend-in-first {
				0% { 
					opacity: 0;
				}
				50% {
					opacity: 0;
				}
				65% {
					opacity: 0.27;
				}
				100% {
					opacity: 0.27;
				}
			}
			
			&:nth-of-type(1) {
				$image-ratio: 2.438;
				$background-image-centering: 0.382;
				$image-preferred-height: 91.8rem;

				/*	preferred image size is 192rem (adapts nicely to font size). 
					But enlarge it to viewport/.slides width or .slides height if required */
				--background-size: max(100%, #{$image-preferred-height}, (100vh - 7rem) * #{$image-ratio});
				/* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
				--background-position-x: calc(40vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2));
				
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0.67;
					z-index: -2;
					
					background: url('/assets/images/1.jpg');
					background-repeat: no-repeat;
					background-position:  var(--background-position-x) center; 
					
					animation: zoom, blend-out-in;
					animation-duration: 26s, 26s;
					animation-delay: 0s, 13s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0;
					z-index: -2;
					
					background: url('/assets/images/1.jpg');
					background-repeat: no-repeat;
					background-position: calc(40vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2)) center; /* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
					
					animation: zoom, blend-in-first, blend-out-in;
					animation-duration: 26s, 26s, 26s;
					animation-delay: 13s, 0s, 26s;
					animation-iteration-count: infinite, 1, infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both, forwards;
				}
				
			}	
			
			&:nth-of-type(2) {
				margin-left: 100%;
				
				$image-ratio: 2.520;
				$background-image-centering: 0.382;
				$image-preferred-height: 165.6rem;
				
				/*	preferred image size is 192rem (adapts nicely to font size). 
					But enlarge it to viewport/.slides width or .slides height if required */
				--background-size: max(100%, #{$image-preferred-height}, (100vh - 7rem) * #{$image-ratio});
				/* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
				--background-position-x: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2));

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0.67;
					z-index: -2;
					
					background: url('/assets/images/2.jpg');
					background-repeat: no-repeat;
					background-position:  var(--background-position-x) center; 
					
					animation: zoom, blend-out-in;
					animation-duration: 26s, 26s;
					animation-delay: 0s, 13s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0;
					z-index: -2;
					
					background: url('/assets/images/2.jpg');
					background-repeat: no-repeat;
					background-position: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2)) center; /* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
					
					animation: zoom, blend-in-first, blend-out-in;
					animation-duration: 26s, 26s, 26s;
					animation-delay: 13s, 0s, 26s;
					animation-iteration-count: infinite, 1, infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both, forwards;
				}				
			}	
			
			&:nth-of-type(3) {
				margin-left: 200%;
				
				$image-ratio: 2.224;
				$background-image-centering: 1;
				$image-preferred-height: 78.1rem;
				
				/*	preferred image size is 192rem (adapts nicely to font size). 
					But enlarge it to viewport/.slides width or .slides height if required */
				--background-size: max(100%, #{$image-preferred-height}, (100vh - 7rem) * #{$image-ratio});
				/* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
				--background-position-x: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2));

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0.67;
					z-index: -2;
					
					background: url('/assets/images/3.jpg');
					background-repeat: no-repeat;
					background-position:  var(--background-position-x) center; 
					
					animation: zoom, blend-out-in;
					animation-duration: 26s, 26s;
					animation-delay: 0s, 13s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					opacity: 0;
					z-index: -2;
					
					background: url('/assets/images/3.jpg');
					background-repeat: no-repeat;
					background-position: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2)) center; /* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
					
					animation: zoom, blend-in-first, blend-out-in;
					animation-duration: 26s, 26s, 26s;
					animation-delay: 13s, 0s, 26s;
					animation-iteration-count: infinite, 1, infinite;
					animation-timing-function: linear;
					animation-fill-mode: both, both, forwards;
				}				
			}
			
			>div {
				>div {
					position: absolute;
					left: calc(100% * 0.382 / 2);
					top: 50%;
					transform: translate(0, -50%);

					>span {
						display: block;
						
						&:nth-of-type(1) {
							font-size: 2.5rem;
							font-family: 'Roboto';
							font-weight: bold;
						}
						
						&:not(:nth-of-type(1)) {
							font-size: 6rem;
							font-family: 'Roboto Bold';
							font-weight: bold;
							
							&:nth-of-type(3) {
								margin-left: 8rem;
							}
											
						}
					}
				}
				
				@keyframes sine {
				    0% {transform: translate(-10%, -50%)}
				    100% {transform: translate(10%, -50%)}
				}

				@keyframes sine-offset {
				    0% {transform: translate(10%, -50%)}
				    100% {transform: translate(-10%, -50%)}
				}

				@keyframes sine-y {
				    0% {top: 50%}
				    100% {top: 60%}
				}

				@keyframes sine-y-offset {
				    0% {top: 57%}
				    100% {top: 43%}
				}
				
				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 47.3rem;
					height: 47.3rem;
					border-radius: 50%;
					background-color: $green;
					mix-blend-mode: multiply;
					left: 16rem;
					
				    animation: sine, sine-y;
					animation-iteration-count: infinite;
					animation-direction: alternate;
				    /* simulate sine */
					animation-timing-function: ease-in-out;
				    animation-duration: 20s;
				    animation-delay: 0s, 10s;
				    animation-fill-mode: backwards;
				}
				
				&:after {
					content: "";
					display: block;
					position: absolute;
					z-index: -1;
					width: 47.3rem;
					height: 47.3rem;
					border-radius: 50%;
					background:	linear-gradient(rgba(249, 255, 239, 0.424), rgba(249, 255, 239, 0.424)), $green;
					mix-blend-mode: hard-light;
					left: 47.5rem;
					margin-top: -13rem;
					
				    animation: sine-offset, sine-y-offset;
					animation-iteration-count: infinite;
					animation-direction: alternate;
				    /* simulate sine */
					animation-timing-function: ease-in-out;
				    animation-duration: 20s;
				    animation-delay: 0s, 10s;
				    animation-fill-mode: backwards;
				}
			}
				
		}
	}

	&.slide1 {
		>div {
			>div {
				transform: translatex(var(--slide-peek-offset));
			}
		}
	}
	
	&.slide2 {
		>div {
			>div {
				transform: translatex(calc(var(--slide-peek-offset) - 100%));
			}
		}
	}

	&.slide3 {
		>div {
			>div {
				transform: translatex(calc(var(--slide-peek-offset) - 200%));
			}
		}
	}
	
	@keyframes slide-in {
		0% { 
			transform: translatex(100vw);
			opacity: 0;
		}
		1% { 
			opacity: 1;
		}
		100% {
			transform: translatex(0);
			opacity: 1;
		}
	}
	
	&.slide2additive {
		>div {
			>div:nth-of-type(2) {
				>div {
					>div {
						>span {
							&:nth-of-type(1) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(2) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0.4s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(3) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0.6s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(4) {
								animation: slide-in;
								animation-duration: 0.9s;
								animation-delay: 0.6s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
    					}
					}
				}
			}
		}
	}

	&.slide3additive {
		>div {
			>div:nth-of-type(3) {
				>div {
					>div {
						>span {
							&:nth-of-type(1) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(2) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0.4s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(3) {
								animation: slide-in;
								animation-duration: 0.5s;
								animation-delay: 0.6s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
							&:nth-of-type(4) {
								animation: slide-in;
								animation-duration: 0.9s;
								animation-delay: 0.6s;
								animation-fill-mode: both;
								animation-timing-function: ease-out;
	    					}
    					}
					}
				}
			}
		}
	}
	
}
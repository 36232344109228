@import '../../styles/partials/variables';

.contact {
	margin-bottom: calc(2.5 * 67.3rem);
	
	>form {
		background-color: $white;
		awidth: 100%;
		aheight: 67.3rem;
		position: relative;
		margin: 0;
		padding-top: 10rem;
		padding-bottom: 10rem;
		padding-left: calc(100% * 0.382 / 2);
		padding-right: calc(100% * 0.382 / 2);
		transform: translate(0, calc(-1 * (2.5 * 67.3rem)));
		position: sticky;
		top: calc(3.5 * 67.3rem + 7rem);
		margin-bottom: calc(-1 * (2.5 * 67.3rem));
		
		>h1 {
			display: inline-block;
			margin: 0;
			font-size: 9rem;
			font-family: 'Roboto';
			font-weight: bold;
			aposition: absolute;
			awidth: calc(50% - 16rem);
			aleft: 16rem;
			atop: 50%;
			atransform: translate(0, -61%);
			word-break: break-word;
			float:left;
		}
		
		>p:nth-of-type(1) {
			margin: 0;
			font-size: 2rem;
			line-height: 2;
			word-break: break-word;
			float: left;
			clear: left;
		}
		
		>address {
			float: right;
			height: 16rem;
			font-size: 1.5rem;
			font-style: normal;
			line-height: 2;
			padding-top: 2.7rem;
		}
		
		input,
		textarea,
		input[type="submit"],
		input[disabled],
		input[type="checkbox"] {
		    border: none;
		    background: none;
		    box-shadow: none;
		    padding: 0;
		    margin: 0;
		    font-size: 2rem;
			font-family: 'Roboto Light';
		    font-style: italic;
		    
		    &:focus {
		    	outline: none;
		    }
		    
		    &.defaultValue {
		    	color: $grey-input-default-value;
		    }
		}
	
		input[type="submit"] {
		    font-style: normal;
			letter-spacing: 0.2rem;	    
		}
	
		>input {
			&:first-of-type { /* email */
				clear: both;
				float: left;
				width: 42%;
				border-bottom: 1px solid $black;
				margin-top: 4.2rem;
				line-height: 2;
				text-align: center;
	   		}
			
			&:nth-of-type(2) { /* phone */
				float: right;
				width: 42%;
				border-bottom: 1px solid $black;
				margin-top: 4.2rem;
				line-height: 2;
				text-align: center;
			}
			
			&:nth-of-type(3) { /* Checkbox */
				transform: scale(2);
				transform-origin: left 35%;
				float: left;
				margin-top: 4.2rem;
			}
			
			&:nth-of-type(4) { /* Submit */
				float: right;
				font-family: 'Roboto';
				margin-top: 2.5rem;
				background-color: $pink;
				line-height: 6.5rem;
				color: $white;
				text-transform: uppercase;
				padding-left: 4.5rem; 
				padding-right: 4.5rem;
				border-radius: 3.25rem;
				
				&:active,
				&.greyedOut {
					background-color: $grey-input-default-value;
				}
			}
		}
	
		>label {
			font-size: 1.5rem;
			float: left;
			padding-left: 2.3rem;
			margin-top: 4.2rem;
		}
			
		>textarea {
			margin-top: 6.3rem;
			width: 100%;
			background-color: $grey;
			border-radius: 2rem;
			padding: 3.1rem 3.7rem;
			box-sizing: border-box;
			height: calc(100vh - 40rem);
			max-height: calc(40rem);
			min-height: 10rem;
		}
		
		>p:nth-of-type(2) {
			font-family: 'Roboto';
			font-size: 1.5rem;
			float: right;
			margin-top: 4.2rem;
			transform: translate(0, -25%);
			margin-right: 2rem;
			display: none;
			
		    &.shown {
				display: block;
		    }
		}
		
		>p:nth-of-type(3) {
			font-family: 'Roboto';
			font-size: 1.5rem;
			font-weight: bold;
			color: $pink;
			float: right;
			margin-top: 4.2rem;
			transform: translate(0, -25%);
			margin-right: 2rem;
			display: none;
			
		    &.shown {
				display: block;
		    }
		}
		
		&:after  {
			content: "";
			display: table;
			clear: both;
		}
	}
}
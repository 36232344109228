@import '../../styles/partials/variables';

.datenschutzerklaerung {
	background:	linear-gradient(rgba(255, 255, 242, 0.424), rgba(255, 255, 242, 0.424)), $green;
	aheight: 67.3rem;
	position: relative;
	padding-top: 13rem;
	padding-bottom: 14rem;
	padding-left: 16rem;
	padding-right: 16rem;
	
	>h1 {
		margin: 0;
		font-size: 9rem;
		font-weight: bold;
	}
	
	>p {
		margin: 0;
		font-size: 2rem;
		line-height: 2;
		width: 50%;
		word-break: break-word;
	}
	
	>h2 {
		margin: 0;
		font-size: 3rem;
		font-weight: bold;
		width: 50%;
		word-break: break-word;
	    padding-top: 3.5rem;
	    padding-bottom: 6rem;
	}
	
	>ul {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 2rem;
		margin-left: -1.4rem;
		
		>li {
			display: inline-block;
			margin-left: 1.4rem;
			text-align: center;
			
			&:before {
				content: "";
				color: blue;
				background-color: $white;
				display: block;
				width: 14rem;
				height: 14rem;
				border-radius: 2rem;
				margin-bottom: 2.2rem;
			}
		}
	}
	
	
}
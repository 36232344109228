$white: #fff;
$black: #000;

$green: #00bc70;
$green-light: #6cd8a8;
$green-lighter: #9fe6c3;

$grey: #e6e6e6;
$pink: #d6006d;
$grey-input-default-value: #bfc4c6;

$gl: 0.618;
$gs: 0.382;
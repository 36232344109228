@import '../../styles/partials/variables';

.header {
	text-align: right;
	position: fixed;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: 1;
    font-family: Roboto;
    	
	>a {
		text-decoration: none;
		display: inline-block;
		vertical-align: top;
		text-align: center;
			
		&:first-child { /* logo link */
			background-image: url('/assets/images/logo.svg');
			width: 16rem;
			height: 7rem;
			overflow: hidden;
			color: transparent;
			background-repeat: no-repeat;
			background-size: 16rem;
			background-position: center;
		    float: left;
		    padding-left: 3.6rem;
		    padding-right: 3.6rem;
   		}
		
		&:not(:first-child):not(:last-child) { /* language selection buttons */
			color: $black;
			font-size: 1.5rem;
			height: 7rem;
			line-height: 7rem;
			padding-left: 0.8rem;

			&:nth-last-child(2) {
				padding-right: 0.8rem;
				margin-right: 3rem;
			}
			
			&:not(:nth-last-child(2)):after {
				content: '';
				display: inline-block;
				width: 0.1rem;
				height: 2rem;
				background-color: $black;
				vertical-align: text-bottom;
				margin-left: 0.8rem;
			}			
		}
		
		&:last-child { /* contact button */
			color: $white;
			background-color: $green;
			height: 7rem;
			line-height: 7rem;
			width: 15.8rem;
			font-weight: bold;
		}
	
	}
}
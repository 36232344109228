@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.technologies {
	background:	linear-gradient(rgba(255, 255, 242, 0.424), rgba(255, 255, 242, 0.424)), $green;
	position: relative;
	padding-top: 13rem;
	padding-bottom: 10rem;
	padding-left: 16rem;
	padding-right: 16rem;
	
	>h1 {
		margin: 0;
		font-size: 8rem;
		font-family: 'Roboto';
		font-weight: bold;
		position: absolute;
		width: calc(50% - 16rem);
		right: 16rem;
		top: 39%;
		transform: translate(0, -50%);
		word-break: break-word;
		text-align: right;
		display: none;

		>span {
			@include whirly-span;
		}
	}
	
	>p {
		margin: 0;
		font-size: 2rem;
		line-height: 2;
		width: 50%;
		word-break: break-word;
	}
	
	>h2 {
		margin: 0;
		font-size: 3rem;
		font-family: 'Roboto';
		font-weight: bold;
		width: 50%;
		word-break: break-word;
	    padding-top: 3.5rem;
	    padding-bottom: 6rem;
	}
	
	>ul {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 2rem;
		margin-left: -1.4rem;
		white-space: nowrap;
		
		>li {
			display: inline-block;
			margin-left: 1.4rem;
			text-align: center;
			margin-bottom: 4rem;
			transform: translate(0, 33rem);
			vertical-align: top;
			
			&:before {
				content: "";
				display: block;
				background: $white;
				background-repeat: no-repeat;
				width: 14rem;
				height: 14rem;
				border-radius: 2rem;
				margin-bottom: 2.2rem;
			}
			
			&:nth-of-type(1):before {
				background: url('/assets/images/tech_java.svg'), $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 44%
			}
			
			&:nth-of-type(2):before {
				background: url('/assets/images/tech_react.svg'), $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 60%
			}

			&:nth-of-type(3):before {
				background: url('/assets/images/tech_db.svg'), $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 51%
			}

			&:nth-of-type(4):before {
				background: url('/assets/images/tech_micro.svg'), $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 60%
			}

			&:nth-of-type(5):before {
				content: "{REST}\A<SOAP>";
				white-space: pre;
			    padding-top: 2.8rem;
			    box-sizing: border-box;
				font-family: 'Roboto';
				font-weight: bold;
				font-size: 3rem;
				background: $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 30%
			}

			&:nth-of-type(6):before {
				content: "Scrum";
				line-height: 14rem;
				font-family: 'Roboto';
				font-weight: bold;
				font-size: 3rem;
				background: $white;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 30%
			}
		}
		
		&.ul-visible {
			>li  {
				transition-property: transform;
				transition-timing-function: ease-out;
				transform: translate(0, 0);
				
				&:nth-of-type(1) {
					transition-delay: 0s;
					transition-duration: 0.4s;
				}
				
				&:nth-of-type(2) {
					transition-delay: 0.2s;
					transition-duration: 0.4s;
				}
				
				&:nth-of-type(3) {
					transition-delay: 0.4s;
					transition-duration: 0.4s;
				}
				
				&:nth-of-type(4) {
					transition-delay: 0.6s;
					transition-duration: 0.4s;
				}

				&:nth-of-type(5) {
					transition-delay: 0.84s;
					transition-duration: 0.5s;
				}

				&:nth-of-type(6) {
					transition-delay: 1.1s;
					transition-duration: 0.6s;
				}
			}
		}
	}
	
	
}
@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.clients {
	height: calc(3.5 * 67.3rem); 

	/* variables */	
	--peek-offset: 0rem;
	--speed: 0.5s;
	
	>div {
		height: 67.3rem;
		position: sticky;
		top: 7rem;
		overflow: hidden;
		
		$image-ratio: 1.818;
		$background-image-centering: 0.382;
		$image-preferred-height: 78.1rem;
				
		/*	preferred image size is 192rem (adapts nicely to font size). 
			But enlarge it to viewport/.slides width or .slides height if required */
		--background-size: max(100%, #{$image-preferred-height}, (100vh - 7rem) * #{$image-ratio});
		/* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
		--background-position-x: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2));
				
		background-color: $black;
		
		/* animation is faded out already at 50+%, so we will only reach a bit more then 10vh zoom */
		@keyframes zoom {
			0% { 
				background-size: calc(var(--background-size) + 0vh); 
				background-position:  calc(var(--background-position-x) + 0vw) center; 
			}
			100% {
				background-size: calc(var(--background-size) + 10vh); 
				background-position:  calc(var(--background-position-x) + 0vw) center; 
			}
		}
		
		@keyframes blend-out-in {
			0% { 
				opacity: 0.68;
			}
			15% {
				opacity: 0;
			}
			50% {
				opacity: 0;
			}
			65% {
				opacity: 0.68;
			}
			100% {
				opacity: 0.68;
			}
		}

		@keyframes blend-in-first {
			0% { 
				opacity: 0;
			}
			50% {
				opacity: 0;
			}
			65% {
				opacity: 0.68;
			}
			100% {
				opacity: 0.68;
			}
		}
		
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0.68;
			z-index: -2;
			
			background: url('/assets/images/10.jpg');
			background-repeat: no-repeat;
			background-position:  var(--background-position-x) center; 
			
			animation: zoom, blend-out-in;
			animation-duration: 40s, 40s;
			animation-delay: 0s, 20s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-fill-mode: both, both;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			z-index: -2;
			
			background: url('/assets/images/10.jpg');
			background-repeat: no-repeat;
			background-position: calc(50vw * #{$background-image-centering} - max(max(100% * #{$background-image-centering} / 2, #{$image-preferred-height} * #{$background-image-centering} / 2), (100vh - 7rem) * #{$image-ratio} * #{$background-image-centering} / 2)) center; /* when image zooms, place center to where text of .slides starts horizontally, so main content will be in center. */
			
			animation: zoom, blend-in-first, blend-out-in;
			animation-duration: 40s, 40s, 40s;
			animation-delay: 20s, 0s, 40s;
			animation-iteration-count: infinite, 1, infinite;
			animation-timing-function: linear;
			animation-fill-mode: both, both, forwards;
		}
				
		>div {
			position: absolute;
			z-index: 1;
   			width: 66.6rem;
			height: 100%;
			clip-path: polygon(0 -1px, 100% -1px, 81% 100%, 0 100%);
			background-color: $green;
			
			>h1 {
				margin: 0;
				font-size: 8rem;
				font-family: 'Roboto';
				font-weight: bold;
				position: absolute;
				padding-left: 16rem;
				top: 50%;
				transform: translate(0, -50%);
				word-break: break-word;
		
				>span {
					@include whirly-span;
				}
			}
		}
		
		>p {
			margin: 0;
			font-size: 2rem;
			line-height: 2;
			position: absolute;
			width: calc(50% - 16rem);
			right: 16rem;
			top: 50%;
			transition-property: transform, opacity;
			transition-duration: var(--speed), 0.25s;
			transform: translate(var(--peek-offset), -50%);
			opacity: 1;
			
			word-break: break-word;
			color: $white;
		}
		
		>ul {
			list-style: none;
			margin: 0;
			padding: 0;
			font-size: 2rem;
			text-align: center;
			position: absolute;
			width: calc(50% + 4rem);
			right: 0rem;
			height: 14rem;
			overflow: hidden;
			top: 50%;
			transition-property: transform;
			transition-duration: var(--speed);
			transform: translate(var(--peek-offset), -50%);
			
			word-break: break-word;
			color: $white;
			
			>li {
				display: inline-block;
				height: 14rem;
				width: 14rem;
				background-color:  $white;
				border-radius: 50%;
				margin-right: 3.2rem;
				text-align: top;
				padding: 0;
				
				&:nth-of-type(1) {
					background: url('/assets/images/clients/thieme.svg'), $white;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 70%
				}
				
				&:nth-of-type(2) {
					background: url('/assets/images/clients/vpv.svg'), $white;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 48%
				}

				&:nth-of-type(3) {
					background: url('/assets/images/clients/vgi.svg'), $white;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 58%
				}

				&:nth-of-type(4) {
					background: url('/assets/images/clients/11.svg'), $white;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 42%
				}
			}
			
		}
	}
	
	&.slide1 {
		>div {
			>ul {
				transform: translate(calc(50vw + 4rem), -50%);
			}
		}
	}
	
	&.slide2 {
		>div {
			>p {
				transform: translate(-50vw, -50%);
				opacity: 0;
				transition-delay: 0s, var(--speed);
			}
		}
	}

	
}